import React, { useState, useMemo, useEffect, Fragment, useCallback } from "react";
import { Row, Col, Form } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setEVIncentiveDetailLoading, updateEVIncentiveDetail, updateAuthPoupState, updateUserDetails } from "../../store/action";
import { clearLoginUserData, setLoginUserData, getUserCurrentZipCode } from "../../helper/sessionStorage";
import * as apiHelper from "../../helper/api_helper";
import TabSection from "./TabSection";
import ModelDetailSection from "./ModelDetailSection";
import ChargingSection from "./ChargingSection";
import { defaultPopUpState, ArrayAsc, isEmpty, DateFormatterLastUpdated, createSeoFriendlyUrl } from '../../helper/project_helper';
import { useAuth, useEVIncentiveDetailSelectedState } from "../../components/hooks/useAuth";
import { withRouter } from "react-router-dom";
import PageLoaderComponent from "../../components/PageLoaderComponent";
import DetailLoder from "../../components/SkeletonLoder/DetailLoder";
import CryptoJS from 'crypto-js';
import ChangePopup from "../../components/ChangePopup";
import { Helmet } from 'react-helmet-async';

const defaultValueIncentiveDetail = (make, model, model_year, trim, userData) => {
    let tax_filing_status = '';
    let household_income = '';

    // if (!isEmpty(userData?.user_attributes?.tax_filing_status)) {
    //     tax_filing_status = userData?.user_attributes?.tax_filing_status;
    // }
    // if (!isEmpty(userData?.user_attributes?.annual_household_income_limit)) {
    //     household_income = userData?.user_attributes?.annual_household_income_limit;
    // }

    return {
        make: make || "",
        model: model || "",
        model_year: model_year || "",
        trim: trim || "",
        tax_filing_status: tax_filing_status,
        household_income: household_income,
        checkEligibility: false,
        lease_term: '',
        annual_mileage: '',
        // zip_code: await getUserCurrentZipCode()
    };
};

const EVIncentiveDetail = ({
    history
}) => {
    let { vehicle_id_hash, purchase_type, ...rest_data } = useParams();

    if (isEmpty(purchase_type) || purchase_type === 'buy') {
        purchase_type = 'Buy';
    }
    if (isEmpty(purchase_type) || purchase_type === 'lease') {
        purchase_type = 'Lease';
    }
    const [defaultValues, setDefaultValues] = useState({});
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [hideViewChangePopup, setHideViewChangePopup] = useState(false);
    const [model_year, setmodelYear] = useState('');
    const [defaultLeaseTerm, setDefaultLeaseTerm] = useState('');
    const [defaultAnnualMileage, setDefaultAnnualMileage] = useState('');
    const [incentiveCheckState, updateIncentiveState] = useEVIncentiveDetailSelectedState();

    const dispatch = useDispatch();
    const location = useLocation();
    const query_parms = new URLSearchParams(location.search);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const lease_term = queryParams.get('lease_term'); // Replace 'myParam' with your query param key 
        const annual_mileage = queryParams.get('annual_mileage'); // Replace 'myParam' with your query param key 
        if (lease_term !== undefined && lease_term !== null) {
            setDefaultLeaseTerm(lease_term);
        }
        if (annual_mileage !== undefined && annual_mileage !== null) {
            setDefaultAnnualMileage(annual_mileage);
        }
    }, [location]);
    const [isLogin, userData] = useAuth();

    const [first, setFirst] = useState(true);
    const [firstCall, setFirstCall] = useState(true);
    const [firstTrim, setFirstTrim] = useState(true);
    const [displaySkeletonView, setDisplaySkeletonView] = useState(true);
    const [filter, setFilter] = useState({});
    const [tmpFilter, setTmpFilter] = useState({});
    const [popupChangeLoading, setPopupChangeLoading] = useState(false);
    const [incentiveAmount, setIncentiveAmount] = useState(0);
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedMoreFeature, setExpandedMoreFeature] = useState(false);
    const [currentTab, setCurrentTab] = useState(purchase_type);
    const [specialIncentives, setSpecialIncentives] = useState(0);
    const [totalSavingAmount, settotalSavingAmount] = useState(0);
    const [incentiveLeaseDetailData, setIncentiveLeaseDetailData] = useState(false);
    const [lastTrim, setLastTrim] = useState('');
    const [displayAGI, setDisplayAGI] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [trimError, setTrimError] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
    // console.log('dddd',filter);
    async function onChangePopUpField(name, value) {
        const tempState = JSON.parse(JSON.stringify(tmpFilter));
        let tempFilterList = JSON.parse(JSON.stringify(filterList));


        tempState[name] = value;

        if (name === "make") {
            tempState.model = "";
            tempState.trim = "";
            tempState.model_year = "";
        }
        if (name === "model") {
            tempState.trim = "";
            tempState.model_year = "";
        }
        if (name === "trim") {
            tempState.model_year = "";
        }

        if (!popupChangeLoading) {
            let list = await getPopupData(tempState);

            if (list?.model_year?.length === 1) {
                tempState.model_year = String(list?.model_year[0]);
            }
            if (tempState?.make !== list?.selected_make && name === 'model' && !isEmpty(list?.selected_make)) {
                tempState.make = list?.selected_make;
            }

            if (name !== 'model') {
                list = await getPopupData(tempState);
                if (list?.model?.length === 1) {
                    if (list?.model[0] !== tempState?.model) {
                        tempState.model = list?.model[0];
                        list = await getPopupData(tempState);
                        if (tempState?.make !== list?.selected_make && !isEmpty(list?.selected_make)) {
                            tempState.make = list?.selected_make;
                        }
                    }
                }
            }

            if (name !== 'trim') {
                list = await getPopupData(tempState);
                if (list?.trim?.length === 1) {
                    if (list?.trim[0] !== tempState?.trim) {
                        tempState.trim = list?.trim[0];
                        list = await getPopupData(tempState);
                    }
                }
            }

            tempFilterList = list;
        }

        if (tempFilterList?.is_blank_trim_available === true && tempFilterList?.available_blank_trim_model_years?.length > 0 && !isEmpty(tempState?.model_year) && tempFilterList?.available_blank_trim_model_years?.includes(tempState?.model_year) !== true) {
            setTrimError(true);
        } else if (tempFilterList?.is_blank_trim_available === false) {
            setTrimError(true);
        } else {
            setTrimError(false);
        }
        setTmpFilter(tempState);
        setFilterList(tempFilterList);
    }

    async function getPopupData(params = {}) {
        // dispatch(setCompareVehiclesLoading(true));
        setPopupChangeLoading(true);
        try {
            const response = await apiHelper.postCompareVehicleFilter(params);
            var resData = [];
            if (response.data.status === 1) {
                resData = response?.data?.body;
            }

            setPopupChangeLoading(false);
            // dispatch(setCompareVehiclesLoading(false));
            return resData
        } catch (error) {
            console.error(error);
            setPopupChangeLoading(false);
            // dispatch(setCompareVehiclesLoading(false));
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional for smooth scrolling 
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function GetVehicleData() {
        dispatch(setEVIncentiveDetailLoading(true));
        try {
            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '') {
                const params = {}
                params.vehicle_id_hash = vehicle_id_hash;
                const buyResponse = await apiHelper.postGetVehicle(params);
                if (buyResponse.status === 200) {
                    if (buyResponse.data.status === 1) {
                        const vehicleData = buyResponse.data.body;
                        setModel(vehicleData?.model);
                        setTrim(vehicleData?.trim);
                        setmodelYear(vehicleData?.model_year);
                        setMake(vehicleData?.make);
                        // const _back = JSON.parse(JSON.stringify(filter));
                        let defaultFil = defaultValueIncentiveDetail(vehicleData?.make, vehicleData?.model, String(vehicleData?.model_year), vehicleData?.trim, userData);
                        if (Object.keys(filter)?.length > 0) {
                            defaultFil = JSON.parse(JSON.stringify(filter));
                            defaultFil.make = vehicleData?.make;
                            defaultFil.model = vehicleData?.model;
                            defaultFil.trim = vehicleData?.trim;
                            defaultFil.model_year = String(vehicleData?.model_year);
                            defaultFil.annual_mileage = filter?.annual_mileage;
                            defaultFil.lease_term = filter?.lease_term;
                        }
                        // setDefaultValues(defaultFil);

                        setFilter(defaultFil);
                        setTmpFilter(defaultFil);

                        dispatch(setEVIncentiveDetailLoading(false));
                        const list = await getPopupData(defaultFil);
                        setFilterList(list);
                    } else if (buyResponse.data.status === 3) {
                        // history.push("/", true)
                        dispatch(setEVIncentiveDetailLoading(false));
                    }
                }
            }

        } catch (error) {
            console.error(error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }

    useEffect(() => {
        GetVehicleData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle_id_hash]);


    const { stateIncentiveDetail } = useSelector((state) => ({
        stateIncentiveDetail: state.EVIncentiveDetail,
    }));

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.AuthPopupState
    }));

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statePopUpData]);

    const onLogin = (x) => {

    }

    const askLogin = () => {
        clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    if (first === true) {
        // if (userData === false || userData === undefined) {
        //     askLogin();
        // }
        // setSpecialIncentives(0);

        // updateIncentiveState({
        //     selectedIncentives: [],
        //     otherIncentives: []
        // });

        // dispatch(updateEVIncentiveDetail({
        //     loading: false,
        //     status: true,
        //     buyData: false,
        //     filter: false
        // }));
        setFirst(false);
    }

    const incentiveBuyDetailData = useMemo(() => {

        if (stateIncentiveDetail.buyData !== false) {
            return stateIncentiveDetail.buyData;
        }
        return null;
    }, [stateIncentiveDetail]);

    const incentiveLeaseDetailDataState = useMemo(() => {

        if (stateIncentiveDetail.leaseData !== false) {
            return stateIncentiveDetail.leaseData;
        }
        return null;
    }, [stateIncentiveDetail]);


    const page_title = useMemo(() => {
        // if (incentiveBuyDetailData !== false) {
        //     if (incentiveBuyDetailData?.page_title !== null) {
        //         return incentiveBuyDetailData?.page_title;
        //     }
        // }
        if (incentiveBuyDetailData !== false) {
            if (!isEmpty(incentiveBuyDetailData?.buy?.make)) {
                return `${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} ${incentiveBuyDetailData?.buy?.segment_name} Deals, Lease, Incentives & Rebates | Siffra`;
            }
        }

        return null;
    }, [incentiveBuyDetailData]);

    const page_meta_description = useMemo(() => {
        // if (incentiveBuyDetailData !== false) {
        //     if (incentiveBuyDetailData?.page_meta_description !== null) {
        //         return incentiveBuyDetailData?.page_meta_description;
        //     }
        // }
        if (incentiveBuyDetailData !== false) {
            if (!isEmpty(incentiveBuyDetailData?.buy?.make)) {
                return `Browse the latest ${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} ${incentiveBuyDetailData?.buy?.segment_name} lease, incentives, deals and rebates in your area at siffra.com. Find all ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} incentives, savings and current offers.`;
            }
        }

        return null;
    }, [incentiveBuyDetailData]);

    const page_meta_keyword = useMemo(() => {
        if (incentiveBuyDetailData !== false) {
            if (!isEmpty(incentiveBuyDetailData?.buy?.make)) {
                return `${incentiveBuyDetailData?.buy?.make}, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.segment_name}, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} ${incentiveBuyDetailData?.buy?.segment_name}, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} Incentives, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} Rebates, ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} federal tax credit, potential savings, ${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} lease`;
            }
        }

        return null;
    }, [incentiveBuyDetailData]);




    useEffect(() => {
        if (!isEmpty(page_title)) {
            document.title = page_title;
        }
        if (!isEmpty(page_meta_description)) {
            document.querySelector('meta[name="description"]').setAttribute("content", page_meta_description);
        }
        if (!isEmpty(page_meta_keyword)) {
            document.querySelector('meta[name="keywords"]').setAttribute("content", page_meta_keyword);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page_title, page_meta_description, page_meta_keyword]);


    const onUpdate = (name, value1) => {
        let value_ = { ...filter };
        value_[name] = value1;
        if (name === 'trim') {
            value_.checkEligibility = false;
            value_.tax_filing_status = "";
            value_.household_income = "";
        }
        setFilter(value_);

    }

    const onReset = () => {
        const temp = { ...filter }
        setFilter({ ...temp, tax_filing_status: '', household_income: "", checkEligibility: false });
        // setSpecialIncentives(0);
        // settotalSavingAmount(parseFloat(incentiveBuyDetailData?.buy?.total_saving_amount))
        // const otherInc = [];
        // incentiveBuyDetailData?.buy?.specialIncentives?.map((incentivesVal, incentivesKey) => {
        //     otherInc.push(incentivesVal?.incentive_id);
        // });

        // updateIncentiveState({
        //     selectedIncentives: [],
        //     otherIncentives: otherInc
        // });
    }

    // var vehicleLoading = useMemo(() => {
    //     return stateIncentiveDetail.vehicleLoading || false;
    // }, [stateIncentiveDetail]);

    // var buyLoading = useMemo(() => {
    //     return stateIncentiveDetail.buyLoading || false;
    // }, [stateIncentiveDetail]);

    // var leaseLoading = useMemo(() => {
    //     return stateIncentiveDetail.leaseLoading || false;
    // }, [stateIncentiveDetail]);

    var isLoading = useMemo(() => {
        return stateIncentiveDetail.loading || false;
    }, [stateIncentiveDetail]);

    // useEffect(() => {

    //     if (isLoading === false) {
    //         setTimeout(() => {
    //             if (displaySkeletonView === true) {
    //                 setDisplaySkeletonView(false);
    //             }
    //         }, 3000);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isLoading]);

    // useEffect(() => {
    //     console.log(vehicleLoading);

    //     if (vehicleLoading === false && buyLoading === false && leaseLoading === false) {
    //         setTimeout(() => {
    //             setIsLoading(false);
    //             if (displaySkeletonView === true) {
    //                 setDisplaySkeletonView(false);
    //             }
    //         }, 3000);
    //     } else {
    //         setIsLoading(true);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [vehicleLoading, buyLoading, leaseLoading]);

    useEffect(() => {
        // if (userData?.tax_filing_status == filter?.tax_filing_status) {
        //     setFilter({ ...filter, household_income: userData?.household_income });
        // } else {
        if (filter?.tax_filing_status !== '') {
            setFilter({ ...filter, household_income: "1" });
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.tax_filing_status]);

    useEffect(() => {
        if (stateIncentiveDetail?.loading === false && stateIncentiveDetail?.error === '' && firstCall === false) {
            // setIsLoading(true);
            getBuyIncentiveDetail(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.checkEligibility]);

    useEffect(() => {
        if (filter !== undefined || filter?.isBoo) {

            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && !isEmpty(make)) {
                getBuyIncentiveDetail(filter, true);
                setFirstCall(false);
            }
            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && !isEmpty(make)) {
                getLeaseIncentiveDetail(filter, true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.make, filter?.model, filter?.trim, filter?.model_year]);

    useEffect(() => {
        if (isLogin) {
            setDefaultValues(defaultValueIncentiveDetail(make, model, model_year, trim, userData))
            setFilter(defaultValues);
            getBuyIncentiveDetail(defaultValues, true);
            getLeaseIncentiveDetail(defaultValues, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);


    useEffect(() => {
        setFilter(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    useEffect(() => {

        if (firstTrim === false) {
            if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== undefined && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== null && stateIncentiveDetail?.buyData?.buy?.vehicle_id !== false) {
                const writePath = '/detail/' + purchase_type?.toLowerCase() + "/" + CryptoJS.MD5(stateIncentiveDetail?.buyData?.buy?.vehicle_id?.toString()).toString() + "/" + createSeoFriendlyUrl(filter?.make) + "/" + createSeoFriendlyUrl(filter?.model) + "/" + filter?.model_year + "/" + createSeoFriendlyUrl(filter?.trim);
                if (location.pathname.includes(writePath) !== true) {
                    history.push(writePath, null, { shallow: true });
                }
            }
        } else {
            setFirstTrim(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateIncentiveDetail?.buyData?.buy?.vehicle_id]);

    useEffect(() => {
        // if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '' && isLogin) {
        if (stateIncentiveDetail.loading === false && stateIncentiveDetail.error === '') {
            const tempIncentiveLeaseDetailDataState = JSON.parse(JSON.stringify({ ...incentiveLeaseDetailDataState }));
            if (filter?.lease_term !== '' && filter?.annual_mileage !== '' && tempIncentiveLeaseDetailDataState?.vehicle_lease_data !== undefined) {

                const index = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.findIndex(item => String(item?.lease_term) === String(filter?.lease_term) && String(item?.annual_mileage) === String(filter?.annual_mileage));

                if (index !== -1) {
                    tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.[index];
                } else {

                    tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = false;

                }
                setIncentiveLeaseDetailData(tempIncentiveLeaseDetailDataState);
            }
        }

        // console.log(tempLeaseData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.lease_term, filter?.annual_mileage]);

    async function getBuyIncentiveDetail(params = {}, dontCheckFilter = false) {
        if (params === undefined || typeof params === 'boolean') {
            return false;
        }

        if (!dontCheckFilter) {
            if (stateIncentiveDetail.filter !== undefined && stateIncentiveDetail.filter !== false) {
                const lastFilter = ArrayAsc(stateIncentiveDetail.filter);
                const currentFilter = ArrayAsc(filter);
                if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                    return false;
                }
            }
        }
        dispatch(setEVIncentiveDetailLoading(true));
        setDisplayAGI(false);
        try {
            params.purchase_type = 'Buy';
            const buyResponse = await apiHelper.postGetEVIncentiveDetail(params);
            var incentiveDetail = false;
            if (buyResponse.data.status === 1) {
                incentiveDetail = buyResponse.data.body;
                if (incentiveDetail?.isLogin === false) {
                    clearLoginUserData();
                    dispatch(updateUserDetails(false));
                }
                if (incentiveDetail?.user_attributes !== undefined && incentiveDetail?.user_attributes !== null && incentiveDetail?.user_attributes !== undefined) {
                    if (filter?.checkEligibility === true) {
                        let tempUserData = { ...userData };
                        tempUserData.user_attributes = incentiveDetail?.user_attributes;
                        setLoginUserData(tempUserData);
                    }
                }
                setLastTrim(filter?.trim);
                dispatch(updateEVIncentiveDetail({
                    loading: false,
                    status: true,
                    buyData: incentiveDetail,
                    filter: filter
                }));


                setTimeout(() => {
                    setDisplayAGI(true);
                }, 100);

            } else if (buyResponse.data.status === 2) {
                dispatch(setEVIncentiveDetailLoading(false));
                askLogin();
            } else {
                dispatch(setEVIncentiveDetailLoading(false));
            }

        } catch (error) {
            console.error(error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }


    async function getLeaseIncentiveDetail(params = {}, dontCheckFilter = false) {

        if (params === undefined || typeof params === 'boolean') {
            return false;
        }

        if (!dontCheckFilter) {
            if (stateIncentiveDetail.filter !== undefined && stateIncentiveDetail.filter !== false) {
                const lastFilter = ArrayAsc(stateIncentiveDetail.filter);
                const currentFilter = ArrayAsc(filter);
                if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
                    return false;
                }
            }
        }

        dispatch(setEVIncentiveDetailLoading(true))
        try {
            var incentiveDetail = false;

            params.purchase_type = 'Lease';
            const leaseResponse = await apiHelper.postGetEVIncentiveDetail(params);
            if (leaseResponse?.data?.status === 1) {
                incentiveDetail = leaseResponse?.data?.body;
                if (incentiveDetail?.vehicle_lease_data !== null) {
                    const tempIncentiveLeaseDetailDataState = JSON.parse(JSON.stringify({ ...incentiveDetail }));

                    if (tempIncentiveLeaseDetailDataState.vehicle_lease_data !== undefined) {
                        const tempLeaseData = JSON.parse(JSON.stringify({ ...incentiveDetail }));
                        tempIncentiveLeaseDetailDataState.vehicle_lease_data.lease_data = tempLeaseData?.vehicle_lease_data?.lease_data?.[0];


                        setIncentiveLeaseDetailData(tempIncentiveLeaseDetailDataState);

                        // console.log(tempIncentiveLeaseDetailDataState);

                        if (defaultAnnualMileage !== '' && defaultLeaseTerm !== '') {
                            let filterValue = { ...filter };
                            filterValue['annual_mileage'] = defaultAnnualMileage;
                            filterValue['lease_term'] = defaultLeaseTerm;
                            setFilter(filterValue);
                        }
                        else if (incentiveDetail?.vehicle_lease_data !== null) {
                            let filterValue = { ...filter };
                            filterValue['annual_mileage'] = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.annual_mileage;
                            filterValue['lease_term'] = tempIncentiveLeaseDetailDataState?.vehicle_lease_data?.lease_data?.lease_term;
                            setFilter(filterValue);
                        }
                    }
                }

            } else {
                dispatch(setEVIncentiveDetailLoading(true))
            }

            setTimeout(() => {
                if (displaySkeletonView === true) {
                    setDisplaySkeletonView(false);
                }
            }, 1500);

            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: true,
                leaseData: incentiveDetail,
                filter: filter
            }));

        } catch (error) {
            console.error("error", error);
            dispatch(updateEVIncentiveDetail({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }


    const handleHideShowClick = (rowId) => {
        setExpandedRows((prev) => ({
            [rowId]: !prev[rowId],
        }));
    };

    const handleChangeClick = () => {
        if (hideViewChangePopup === true) {
            setTmpFilter(filter);
        }
        setHideViewChangePopup(!hideViewChangePopup)
    };
    const onChangeClickVehicle = () => {
        setFilter(tmpFilter);
        setHideViewChangePopup(false);
    };
    return (
        <Fragment>
            <Helmet>
                <title>{page_title}</title>
                <meta name="description" content={page_meta_description} />
            </Helmet>
            {/* {displaySkeletonView && */}
            <PageLoaderComponent loading={isLoading || displaySkeletonView} />
            {/* } */}
            {/* {displaySkeletonView && */}
            {displaySkeletonView === true &&
                <DetailLoder />
            }
            {(isEmpty(displaySkeletonView?.error?.msg) && !displaySkeletonView) ? (
                <div className={"detail-main main-animation"}>
                    <section className="detaile-top-sticky">
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex align-items-center justify-content-between flex-wrap bg-detail-top py-3 px-lg-5 px-3">
                                    <div className="d-flex flex-column align-items-center">
                                        <h1 className="font-wx font-largeX font-white">{`${model_year} ${make} ${model}`}</h1>
                                        <div className="d-sm-flex align-items-center">
                                            <div className="d-flex trim-select-div">
                                                <p
                                                    className="font-midium font-wxl font-white"
                                                    style={{ margin: '0' }}
                                                >
                                                    {incentiveBuyDetailData?.buy?.trim}
                                                </p>
                                            </div>
                                            <div className="change-pro">
                                                <span className="font-midium font-w4 font-white"></span>
                                                <button className="font-midium font-wx dropdown compare-change-button font-white" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => handleChangeClick()}>Change</button>
                                            </div>
                                            {hideViewChangePopup &&
                                                <ChangePopup
                                                    popupLoading={popupChangeLoading}
                                                    filterList={filterList}
                                                    filter={tmpFilter}
                                                    filterState={filter}
                                                    onChangePopUpField={onChangePopUpField}
                                                    trimError={trimError}
                                                    onChangeClickVehicle={onChangeClickVehicle}
                                                    handleChangeClick={handleChangeClick} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <ModelDetailSection
                        incentiveBuyDetailData={incentiveBuyDetailData}
                        chargingDetail={incentiveBuyDetailData?.charging_data}
                        setExpandedMoreFeature={setExpandedMoreFeature}
                        expandedMoreFeature={expandedMoreFeature}
                        expandedRows={expandedRows}
                        isLoading={isLoading}
                    />

                    <TabSection
                        incentiveBuyDetailData={incentiveBuyDetailData}
                        incentiveLeaseDetailData={incentiveLeaseDetailData}
                        filter={filter}
                        setFilter={setFilter}
                        incentiveAmount={incentiveAmount}
                        setIncentiveAmount={setIncentiveAmount}
                        handleHideShowClick={handleHideShowClick}
                        expandedRows={expandedRows}
                        tooltipText={incentiveBuyDetailData?.tooltips}
                        onReset={onReset}
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}
                        askLogin={askLogin}
                        setExpandedRows={setExpandedRows}
                        settotalSavingAmount={settotalSavingAmount}
                        setSpecialIncentives={setSpecialIncentives}
                        specialIncentives={specialIncentives}
                        totalSavingAmount={totalSavingAmount}
                        incentiveCheckState={incentiveCheckState}
                        updateIncentiveState={updateIncentiveState}
                        isLoading={isLoading}
                        isLogin={isLogin}
                        displayAGI={displayAGI}
                    />
                    <ChargingSection
                        chargingDetail={incentiveBuyDetailData?.charging_data}
                        chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                        tooltipText={incentiveBuyDetailData?.tooltips}
                        isLoading={isLoading}
                    />
                    {/* <section className="mx-lg-5 mx-3 "> */}
                    {!isEmpty(incentiveBuyDetailData?.last_updated_date) &&
                        <Row className="pt-5 px-lg-5 px-3 pb-2 last-updated-date">
                            <Col>
                                <label className="font-small font-w4 font-black">Last updated: {DateFormatterLastUpdated(incentiveBuyDetailData?.last_updated_date)}</label>
                            </Col>
                        </Row>
                    }
                    {/* </section> */}
                </div>
            ) :
                <main className="blank-page main-animation"></main>
            }
        </Fragment>
    )

}

export default withRouter(EVIncentiveDetail);


