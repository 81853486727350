import { Row, Col, Nav, Tab } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import LeaseIncentiveSection from "./LeaseIncentiveSection";
import BuyIncentiveSection from "./BuyIncentiveSection";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";


const TabSection = ({
    incentiveBuyDetailData,
    incentiveLeaseDetailData,
    filter,
    setFilter,
    incentiveAmount,
    setIncentiveAmount,
    handleHideShowClick,
    expandedRows,
    tooltipText,
    onReset,
    currentTab,
    setCurrentTab,
    askLogin,
    setExpandedRows,
    settotalSavingAmount,
    setSpecialIncentives,
    specialIncentives,
    totalSavingAmount,
    incentiveCheckState,
    updateIncentiveState,
    isLoading,
    isLogin,
    displayAGI
}) => {

    const [blurleaseDisabled, setBlurLeaseDisabled] = useState(false);

    useEffect(() => {

        if (Number(incentiveBuyDetailData?.buy?.lease_payment_count) === 0 && Number(incentiveBuyDetailData?.buy?.lease_incentives_count) === 0) {
            setBlurLeaseDisabled(true);
        }
        // else {
        //     setBlurLeaseDisabled(false);
        //     if (!isLogin) {
        //         setLeaseLock(true);
        //     }else{
        //         setLeaseLock(false);
        //     }
        // }
    }, [incentiveBuyDetailData]);

    return (
        <section className="mx-lg-5 mx-3 mt-2">
            <Tab.Container id="left-tabs-example" defaultActiveKey={currentTab} activeKey={currentTab}>
                <Col lg={12}>
                    <Nav variant="pills" className="flex-column">
                        <Row>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <Nav.Item>
                                    <Nav.Link className='green-hover-text' eventKey="Buy"
                                        onClick={() => {
                                            setCurrentTab('Buy');
                                            setExpandedRows({});
                                        }}
                                    >Buy</Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <Nav.Item>
                                    <Nav.Link
                                        className={blurleaseDisabled ? "blur-text" : "green-hover-text"}
                                        disabled={blurleaseDisabled}
                                        eventKey="Lease"
                                        onClick={() => {
                                            if (!blurleaseDisabled) {
                                                setCurrentTab('Lease');
                                                setExpandedRows({});
                                            }
                                        }}
                                    >Lease
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Row>
                    </Nav>
                </Col>
                <Col lg={12}>
                    <Tab.Content>
                        <BuyIncentiveSection
                            buyDetail={incentiveBuyDetailData?.buy}
                            filter={filter}
                            isLogin={isLogin}
                            tax_filing_status={incentiveBuyDetailData?.tax_filing_status}
                            household_income={incentiveBuyDetailData?.household_income}
                            setUpdatedFilter={setFilter}
                            incentiveAmount={incentiveAmount}
                            setIncentiveAmount={setIncentiveAmount}
                            handleHideShowClick={handleHideShowClick}
                            expandedRows={expandedRows}
                            tooltipText={tooltipText}
                            onReset={onReset}
                            chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                            settotalSavingAmount={settotalSavingAmount}
                            setSpecialIncentives={setSpecialIncentives}
                            specialIncentives={specialIncentives}
                            totalSavingAmount={totalSavingAmount}
                            incentiveCheckState={incentiveCheckState}
                            updateIncentiveState={updateIncentiveState}
                            isLoading={isLoading}
                            displayAGI={displayAGI}
                        />
                        <LeaseIncentiveSection
                            leaseDetail={incentiveLeaseDetailData}
                            filter={filter}
                            setUpdatedFilter={setFilter}
                            handleHideShowClick={handleHideShowClick}
                            expandedRows={expandedRows}
                            // chargingIncentives={incentiveBuyDetailData?.buy?.chargingIncentives}
                            chargingIncentives={incentiveLeaseDetailData?.vehicle_lease_data?.chargingIncentives}
                            tooltipText={tooltipText}
                            isLoading={isLoading}
                        />
                    </Tab.Content>
                </Col>
            </Tab.Container>
        </section>
    )
}

export default withRouter(TabSection);