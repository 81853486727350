import React, { Fragment } from "react";
import { NumberWithThousandSeparator } from "../../helper/project_helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import { withRouter, Link } from "react-router-dom";
import { isEmpty, handleDragStart, handleContextMenu, createSeoFriendlyUrl } from "../../helper/project_helper";
import CheckGreen from "../../components/WebComponent/CheckGreen";
import RedClose from "../../components/WebComponent/RedClose";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CryptoJS from 'crypto-js';

import {
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";

const NewIncentiveFinderByVehicleTable = ({
  incentives = [],
  loading = true,
  notFound = false,
  disabled = false,
  onSavingClick,
  expandedRows,
  history,
  compareVehicles,
  handleCompareCheck,
  getCompareValue,
  valueCompareCheck,
  onChangeCompareCheck,
  tooltipText
}) => {

  const handleRowClick = (rowId) => {
    onSavingClick((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  return (
    <Fragment>
      <div className="table-striped w-100 table-hover fixed-table">
        <div className="NewTbody">
          {!loading && incentives && (
            incentives?.map((item_val, item_key) => {
              if ("maker" in item_val) {
                return (
                  <div className="heading-tr" key={item_key}>
                    <div className="py-2 px-4 d-flex align-items-center font-large font-color font-wxl" style={{ height: "42px" }}>
                      {item_val?.maker}{" "}
                      <div className="ms-2">
                        <img
                          className="manufacturer-logo-link"
                          src={process.env.REACT_APP_VEHICLE_LOGO_BASE_URL + item_val?.manufacturer_logo_link}
                          // alt={item_val?.maker}
                          alt=""
                          onContextMenu={handleContextMenu}
                          onDragStart={handleDragStart}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <Fragment key={"model-row" + item_key}>
                    <div className={"bottom-border-2x d-flex align-items-center justify-content-between main-animation"} style={{ background: '#fff', padding: "12px 0px 12px 0", overflow: "visible" }}>
                      <div className="px-4"><span className="font-large font-w font-color cursor-pointer green-hover-text" onClick={() =>
                        handleRowClick(
                          "hidden_model_row" + item_key
                        )
                      }>{item_val?.makerModel}</span></div>
                      <div className="py-2 px-4">
                        {/* {item_val?.segment} */}
                        {item_val?.seating !== null && (
                          <Fragment>
                            {" "}
                            {expandedRows["hidden_model_row" + item_key] ? (
                              <FontAwesomeIcon
                                icon={faChevronUp}
                                onClick={() =>
                                  handleRowClick(
                                    "hidden_model_row" + item_key
                                  )
                                }
                                className="cursor-pointer"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                onClick={() =>
                                  handleRowClick(
                                    "hidden_model_row" + item_key
                                  )
                                }
                                className="cursor-pointer"
                              />
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>

                    {/* seating */}
                    {expandedRows["hidden_model_row" + item_key] &&
                      <MultipleTrim trimData={item_val?.trimData} expandedRows={expandedRows} onExpCol={(trimKey) =>
                        handleRowClick(trimKey)
                      } history={history}
                        handleCompareCheck={handleCompareCheck}
                        compareVehicles={compareVehicles}

                        valueCompareCheck={valueCompareCheck}
                        onChangeCompareCheck={onChangeCompareCheck}
                        getCompareValue={getCompareValue}
                        tooltipText={tooltipText}

                      />
                    }

                  </Fragment>
                );
              }
            })
          )}
        </div>
      </div>
    </Fragment>
  );
};

const MultipleTrim = ({
  trimData,
  expandedRows,
  onExpCol,
  history,
  handleCompareCheck,
  compareVehicles,
  getCompareValue,

  valueCompareCheck,
  onChangeCompareCheck,
  tooltipText

}) => {

  return (
    trimData?.map((trim_item_val, trim_item_key) => {
      return (
        <Fragment key={'mul-trim-tr' + trim_item_key}>
          <div className={"main-animation d-flex align-items-end " + (!expandedRows["hidden_trim_row" + trim_item_val?.vehicle_id] && "bottom-border-2x")} style={{ background: '#fff', padding: "30px 0px 15px 0px" }} >
            <div className="ps-5 v-model" style={{ width: "26%" }}>
              <div>
                {/* {trim_item_val.model} */}
                <p className="m-0 font-midium font-wx cursor-pointer green-hover-text" style={{ position: "absolute" }} onClick={() =>{

                  if(expandedRows["hidden_trim_row" + trim_item_val?.vehicle_id])
                  {
                    history.push('/detail/' + trim_item_val?.purchase_type?.toLowerCase() + "/" + CryptoJS.MD5(trim_item_val?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(trim_item_val?.make) + "/" + createSeoFriendlyUrl(trim_item_val?.model) + "/" + trim_item_val?.model_year + "/" + createSeoFriendlyUrl(trim_item_val?.trim), true);
                  }else{
                    onExpCol("hidden_trim_row" + trim_item_val?.vehicle_id)
                  }
                }
                }>{trim_item_val?.trim.trim() !== '' ? trim_item_val?.trim : trim_item_val?.model}</p>
              </div>
              <Form>
                <div className="" style={{ marginTop: "35px" }}>
                  <Form.Group className="form-checkbox d-flex align-items-center">
                    <Form.Label className="car-lease m-0 me-2 font-small">Compare</Form.Label>
                    <Form.Check
                      value={trim_item_val?.vehicle_id + "|" + trim_item_val?.make + "|" + trim_item_val?.model + "|" + trim_item_val?.model_year + "|" + trim_item_val?.trim}
                      checked={getCompareValue(trim_item_val?.vehicle_id)}
                      onChange={handleCompareCheck}
                    />
                  </Form.Group>
                </div>
              </Form>
            </div>
            <div className="d-flex align-items-end fill-width mt-3">
              <div className="font-midium font-color font-wxl Model-Year-data" style={{ width: "18%" }}>
                {trim_item_val?.model_year}
              </div>
              <div className="v_class font-midium font-color font-wxl Segment-data" style={{ width: "15%" }}>
                {trim_item_val?.segment_name}
                {trim_item_val?.seating !== null && (
                  <Fragment>
                    {" "}
                    <span> </span>
                  </Fragment>
                )}
              </div>
              <div className="nonewrep font-midium font-color font-wxl renge-width" style={{ width: "17%" }}>
                {trim_item_val.range_low === 0 && trim_item_val.range_high === 0
                  ? "TBA"
                  : trim_item_val.range_low === trim_item_val.range_high
                    ? trim_item_val.range_high
                    : trim_item_val.range_low + "-" + trim_item_val.range_high}
                &nbsp;{trim_item_val.range_low !== 0 && trim_item_val.range_high !== 0 && "mi"}
              </div>
              <div className="font-midium font-color font-wxl base-data" style={{ width: "18%" }}>
                $
                {trim_item_val.base_msrp
                  ? NumberWithThousandSeparator(trim_item_val.base_msrp)
                  : 0}
              </div>
              <div className="font-midium font-color font-wxl Potential-data" style={{ width: "17%" }}>
                $
                {trim_item_val?.total_saving_amount
                  ? NumberWithThousandSeparator(
                    trim_item_val?.total_saving_amount
                  )
                  : 0}
              </div>
              <div className="Federal-data" style={{ minWidth: "10.5%" }}>
                {trim_item_val?.fed_tax_credit_amount > 0 ? (
                  // Inline checkGreen SVG
                  <CheckGreen height={32} width={32} />
                ) : (
                  // Inline closeDanger SVG
                  <RedClose />
                )}
              </div>
              <div className="text-center">
                <div className="d-flex align-items-center justify-content-center">
                  <Link className="btn-learn-more text-decoration-none d-flex align-items-center lp-detail-btn" to={'/detail/' + trim_item_val?.purchase_type?.toLowerCase() + "/" + CryptoJS.MD5(trim_item_val?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(trim_item_val?.make) + "/" + createSeoFriendlyUrl(trim_item_val?.model) + "/" + trim_item_val?.model_year + "/" + createSeoFriendlyUrl(trim_item_val?.trim)}>
                    Details
                    <svg className="ms-1" width="15px" height="15px" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                  </Link>
                  {expandedRows["hidden_trim_row" + trim_item_val?.vehicle_id] ?
                    (<i className="ms-1 mt-1" onClick={() =>
                      onExpCol("hidden_trim_row" + trim_item_val?.vehicle_id)
                    }><svg className="minus cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="#1E1E1F" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3m0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5m-6 10v2h12v-2z" /></svg></i>) :

                    (<i className="ms-1 mt-1" onClick={() =>
                      onExpCol("hidden_trim_row" + trim_item_val?.vehicle_id)
                    }><svg className="plus cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="#1E1E1F" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3m0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5m-1 5v5h-5v2h5v5h2v-5h5v-2h-5v-5z" /></svg></i>)
                  }

                </div>
              </div>
            </div>
          </div>
          {expandedRows["hidden_trim_row" + trim_item_val?.vehicle_id] && (
            <Fragment>
              <div className="d-flex data-bg pb-3 bottom-border-2x main-animation">
                <div rowSpan={2} className="py-2 nonewrep model-td text-center" style={{ width: "25%" }}>
                  <div className="evf-img">
                    {/* <img
                      className="landing-page-image"
                      onContextMenu={handleContextMenu}
                      onDragStart={handleDragStart}
                      src={process.env.REACT_APP_VEHICLE_BASE_URL + trim_item_val?.make?.replace(/-/g, '+') + "/" + trim_item_val?.model_year + "/" + trim_item_val?.vehicle_image_link?.replace(/\+/g, '%2B')}
                      // alt={trim_item_val?.make + " " + trim_item_val?.model+ " " + trim_item_val?.trim + " " + trim_item_val?.model_year}
                      alt=""
                    /> */}
                    <LazyLoadImage
                      alt=""
                      effect="opacity"
                      src={process.env.REACT_APP_VEHICLE_BASE_URL + trim_item_val?.make?.replace(/-/g, '+') + "/" + trim_item_val?.model_year + "/" + trim_item_val?.vehicle_image_link?.replace(/\+/g, '%2B')}
                    />

                  </div>
                </div>
                <div className="width-fill ">
                  <div className={"d-flex align-items-center ms-4"}>
                    <div style={{ width: "30%" }}>
                      <div className="py-2 text-center">
                        <div className="d-flex align-items-center">
                          <p className="ps-3 car-postential"><b>Potential Savings</b></p>
                          {!isEmpty(tooltipText?.[2]) &&
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip className="custom-tooltip ">
                                  <div className="tc-text">
                                    {tooltipText?.[2]}
                                  </div>
                                </Tooltip>
                              }
                            >
                              {/* <img width="13px" src={infosvg} alt="" /> */}
                              <svg className="ms-1 overflow-visible" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 13 12" fill="none">
                                <path d="M6 11.8926C4.60761 11.8926 3.27226 11.3395 2.28769 10.3549C1.30312 9.37032 0.75 8.03497 0.75 6.64258C0.75 5.25019 1.30312 3.91483 2.28769 2.93027C3.27226 1.9457 4.60761 1.39258 6 1.39258C7.39239 1.39258 8.72774 1.9457 9.71231 2.93027C10.6969 3.91483 11.25 5.25019 11.25 6.64258C11.25 8.03497 10.6969 9.37032 9.71231 10.3549C8.72774 11.3395 7.39239 11.8926 6 11.8926ZM6 12.6426C7.5913 12.6426 9.11742 12.0104 10.2426 10.8852C11.3679 9.76 12 8.23388 12 6.64258C12 5.05128 11.3679 3.52516 10.2426 2.39994C9.11742 1.27472 7.5913 0.642578 6 0.642578C4.4087 0.642578 2.88258 1.27472 1.75736 2.39994C0.632141 3.52516 0 5.05128 0 6.64258C0 8.23388 0.632141 9.76 1.75736 10.8852C2.88258 12.0104 4.4087 12.6426 6 12.6426Z" fill="black" />
                                <path d="M6.6975 5.58358L4.98 5.79883L4.9185 6.08383L5.256 6.14608C5.4765 6.19858 5.52 6.27808 5.472 6.49783L4.9185 9.09883C4.773 9.77158 4.99725 10.0881 5.5245 10.0881C5.93325 10.0881 6.408 9.89908 6.62325 9.63958L6.68925 9.32758C6.53925 9.45958 6.32025 9.51208 6.17475 9.51208C5.9685 9.51208 5.8935 9.36733 5.94675 9.11233L6.6975 5.58358ZM6.75 4.01758C6.75 4.21649 6.67098 4.40726 6.53033 4.54791C6.38968 4.68856 6.19891 4.76758 6 4.76758C5.80109 4.76758 5.61032 4.68856 5.46967 4.54791C5.32902 4.40726 5.25 4.21649 5.25 4.01758C5.25 3.81867 5.32902 3.6279 5.46967 3.48725C5.61032 3.3466 5.80109 3.26758 6 3.26758C6.19891 3.26758 6.38968 3.3466 6.53033 3.48725C6.67098 3.6279 6.75 3.81867 6.75 4.01758Z" fill="black" />
                              </svg>
                            </OverlayTrigger>
                          }
                        </div>
                        <h4 className="mt-1 font-largexl max-Potential font-w8 font-color">
                          $
                          {trim_item_val?.total_saving_amount !== null
                            ? NumberWithThousandSeparator(
                              trim_item_val?.total_saving_amount
                            )
                            : 0}
                        </h4>
                      </div>
                      {trim_item_val?.lease_incentive_available > 0 && (<li className="m-0 car-lease ">Lease incentives available.</li>)}
                      {trim_item_val?.charging_incentive_available > 0 && (<li className="m-0 car-lease">Charging incentives available.</li>)}
                    </div>
                    <div style={{ width: "30%" }}>
                      {trim_item_val?.total_potential_savings &&
                        trim_item_val?.total_potential_savings?.map((saving_amount_val, saving_amount_key) => {
                          return (<div className="d-flex align-items-center justify-content-between tex-detail" key={saving_amount_key}>
                            <span className="replace-hyphen">{isEmpty(saving_amount_val?.incentive_name_text) ? saving_amount_val?.incentive_name : saving_amount_val?.incentive_name_text}</span>
                            <span className="replace-hyphen">{isEmpty(saving_amount_val?.incentive_amount_text) ? saving_amount_val?.incentive_amount : saving_amount_val?.incentive_amount_text}</span>
                          </div>)
                        })
                      }
                    </div>
                    <div style={{ width: "40%" }}>
                      <div className="d-flex flex-column align-items-center">
                        {/* <Link className="btn-learn-more text-decoration-none d-flex align-items-center" to={'/detail/' + trim_item_val?.purchase_type?.toLowerCase() + "/" + trim_item_val?.vehicle_id + "/" + createSeoFriendlyUrl(trim_item_val?.make) + "/" + createSeoFriendlyUrl(trim_item_val?.model) + "/" + trim_item_val?.model_year + "/" + createSeoFriendlyUrl(trim_item_val?.trim)}>
                          Details
                          <svg className="ms-3" width="15px" height="15px" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )
    })
  )
};


export default withRouter(NewIncentiveFinderByVehicleTable);