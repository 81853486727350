import React from "react";
import { Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import MyLink from '../components/MyLink';

const Footer = ({
    history
}) => {

    return (
        <div>
            <div expand="lg" className="py-5" style={{ background: "#0F2213" }}>
                <div className="mx-5 d-flex align-items-center justify-content-between footer-main">
                    <Navbar.Brand className="text-small copy-right font-w3 font-white d-flex align-items-center" href="#">Siffra&nbsp;&nbsp;<svg width="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM199.4 312.6c-31.2-31.2-31.2-81.9 0-113.1s81.9-31.2 113.1 0c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9c-50-50-131-50-181 0s-50 131 0 181s131 50 181 0c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0c-31.2 31.2-81.9 31.2-113.1 0z" /></svg>&nbsp;&nbsp;{new Date().getFullYear()}</Navbar.Brand>
                    <div className="d-flex">
                        <MyLink className="border-0 text-small me-4 text-decoration-none font-w3 font-white" to="/privacypolicy"
                            onClick={(x) => {
                                history.push("/privacypolicy", true);
                            }}
                        >Privacy Policy</MyLink>
                        <MyLink className="border-0 text-small text-decoration-none font-w3 font-white" to="/contact">Contact</MyLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Footer);