import React from "react";
import brandimg from '../../assets/img/logo_green 1.png'

const Basic404 = () => {

    return (
        <section className="page-not-found d-flex flex-column align-items-center justify-content-center">
            <img className="logoimg mb-3" src={brandimg} alt=""></img>
            <div className="text-center mb-3">
                <h1>404 Page not found</h1>
            </div>
            <div>
            <a className="btn-learn-more text-decoration-none d-flex align-items-center" href="/">Go to homepage<svg className="ms-1" width="15px" height="15px" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path></svg></a>
            </div>
        </section>

    );
}


export default Basic404;