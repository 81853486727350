import React, { useState, useMemo, useCallback, Fragment, useEffect } from "react";
import IncentiveDetailSearchDesktop from './IncentiveDetailSearchDesktop'
import IncentiveDetailSearchMobile from './IncentiveDetailSearchMobile'
import { useAuth } from "../../components/hooks/useAuth";
import { useSelector, useDispatch } from "react-redux";
import { defaultPopUpState, isEmpty, isValidateZipcode, createSeoFriendlyUrl } from '../../helper/project_helper';
import { setLocalStorageData, getLocalStorageData, removeLocalStorageData } from '../../helper/sessionStorage';
import { clearLoginUserData, getUserCurrentZipCode } from "../../helper/sessionStorage";
import * as apiHelper from "../../helper/api_helper";
import { withRouter, useParams } from "react-router-dom";
import {
    setIncentiveDetailSearchFiltersLoading,
    updateIncentiveDetailSearchFiltersData,
    setIncentiveDetailSearchLoading,
    updateIncentiveDetailSearchData,
    updateAuthPoupState,
    updateUserDetails
} from "../../store/action";
import PageLoaderComponent from "../../components/PageLoaderComponent";
import * as helper from "../../helper/sessionStorage";
import useIsMobile from '../../components/hooks/useIsMobile';
import CryptoJS from 'crypto-js';
import { Helmet } from 'react-helmet-async';

export const defaultValueIncentiveDetailSearchFilter = (userData, default_model_year = "", reset = false, permData = false, default_zip_code = '', currentZipCode = '') => {

    let zip_code = '';
    // console.log(default_zip_code);
    
    if (!isEmpty(default_zip_code)) {
        zip_code = default_zip_code;
    }
    
    if (!isEmpty(currentZipCode) && isEmpty(zip_code)) {
        zip_code = currentZipCode;
    }
    // console.log('currentZipCode',currentZipCode);

    if (!isEmpty(userData?.zip_code) && isEmpty(zip_code)) {
        zip_code = userData?.zip_code
    }

    if (reset === true) {
        zip_code = '';
        removeLocalStorageData('user_typed_zip_code');
    }

    return {
        zip_code: zip_code,
        make: !isEmpty(permData?.make) ? permData?.make : '',
        model: !isEmpty(permData?.model) ? permData?.model : '',
        trim: !isEmpty(permData?.trim) ? permData?.trim : '',
        model_year: default_model_year,
        vehicle_condition: "New",
        purchase_type: !isEmpty(permData?.purchase_type) ? permData?.purchase_type : 'Buy'
    };
};
export const defaultBlankFilterError = () => {
    return {
        make: false,
        model: false,
        trim: false,
        model_year: false,
    };
};

const IncentiveDetailSearch = ({
    history
}) => {
    let { purchase_type, vehicle_id_hash } = useParams();
    const dispatch = useDispatch();
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [model_year, setmodelYear] = useState('');
    const [success, setSuccess] = useState(false);
    const [toTop, setToTop] = useState(true);
    const [isLogin, userData] = useAuth();
    const [currentZipCode, setCurrentZipCode] = useState('');

    if (toTop === true) {
        // console.log("call scroll to top")
        window.scrollTo(0, 0); // Add a small delay
        setToTop(false);
    }

    async function GetVehicleData() {
        dispatch(setIncentiveDetailSearchLoading(true));
        try {
            const params = {}
            params.vehicle_id_hash = vehicle_id_hash;
            const buyResponse = await apiHelper.postGetVehicle(params);
            if (buyResponse.status === 200) {
                if (buyResponse.data.status === 1) {
                    const vehicleData = buyResponse.data.body;
                    setModel(vehicleData?.model);
                    setTrim(vehicleData?.trim);
                    setmodelYear(vehicleData?.model_year);
                    setMake(vehicleData?.make);
                    // if (!isLogin && isEmpty(getLocalStorageData('user_typed_zip_code'))) {
                    //     const zip = await getUserCurrentZipCode();
                    //     console.log('vzip',zip);
                        
                    //     setCurrentZipCode(zip);
                    //     setSuccess(true);
                    // } else {
                    //     setSuccess(true);
                    // }
                    
                    // comment when want to start current zipcode 
                    setSuccess(true);
                    dispatch(setIncentiveDetailSearchLoading(false));
                } else if (buyResponse.data.status === 3) {
                    history.push("/", true)
                    dispatch(setIncentiveDetailSearchLoading(false));
                }
            }

        } catch (error) {
            console.error(error);
            setSuccess(true);
            dispatch(updateIncentiveDetailSearchFiltersData({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }

    useEffect(() => {
        const asyncFunction = async () => {
            if (!isEmpty(vehicle_id_hash)) {
                GetVehicleData();
            } else {
                // removeLocalStorageData('user_typed_zip_code');
                // if (!isLogin && isEmpty(getLocalStorageData('user_typed_zip_code'))) {
                //     const zip = await getUserCurrentZipCode();
                //     console.log("zip",zip);
                    
                //     setCurrentZipCode(zip);
                //     setSuccess(true);
                // } else {
                //     setSuccess(true);
                // }

                // comment when want to start current zipcode
                setSuccess(true);
            }
        }
        asyncFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { stateIncentiveDetailSearch } = useSelector((state) => ({
        stateIncentiveDetailSearch: state.IncentiveDetailSearch,
    }));

    return (
        <Fragment>
            <PageLoaderComponent loading={stateIncentiveDetailSearch?.loading} />
            {success ?
                <IncentiveDetailSearchChild
                    history={history}
                    purchase_type={purchase_type}
                    make={make}
                    model={model}
                    model_year={model_year}
                    trim={trim}
                    currentZipCode={currentZipCode}
                /> :
                <div className="blank-page">

                </div>
            }
        </Fragment>
    );

}
const IncentiveDetailSearchChild = ({
    history,
    make,
    model,
    model_year,
    trim,
    purchase_type,
    currentZipCode
}) => {
    const permData = {
        purchase_type: purchase_type,
        make: make,
        model: model,
        model_year: model_year,
        trim: trim
    };
    const dispatch = useDispatch();
    const [isLogin, userData] = useAuth();
    const isMobile = useIsMobile();


    const { stateIncentiveDetailSearchFilters } = useSelector((state) => ({
        stateIncentiveDetailSearchFilters: state.IncentiveDetailSearchFilters,
    }));
    const { stateIncentiveDetailSearch } = useSelector((state) => ({
        stateIncentiveDetailSearch: state.IncentiveDetailSearch,
    }));

    const [expandedRows, setExpandedRows] = useState({});
    const [firstFind, setFirstFind] = useState(true);
    const [firstFilter, setFirstFilter] = useState(isEmpty(make) ? false : true);
    const [firstSearch, setFirstSearch] = useState(true);
    const [onReste, setOnReset] = useState(false);

    const [filterState, setFilterState] = useState(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData,getLocalStorageData('user_typed_zip_code'), currentZipCode));
    const [filter, setFilter] = useState(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData, getLocalStorageData('user_typed_zip_code'), currentZipCode));

    const [zipCodeError, setZipCodeError] = useState(false);
    const [blankFilterError, setBlankFilterError] = useState(defaultBlankFilterError());
    const [lastValidatedZipcode, setlastValidatedZipcode] = useState("");
    const [currentFilterChangeName, setCurrentFilterChangeName] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.AuthPopupState
    }));


    const page_title = useMemo(() => {
        if (stateIncentiveDetailSearchFilters?.data !== false) {
            if (stateIncentiveDetailSearchFilters?.data?.page_title !== null) {
                return stateIncentiveDetailSearchFilters?.data?.page_title;
            }
        }
        return null;
    }, [stateIncentiveDetailSearchFilters]);

    const page_meta_description = useMemo(() => {
        if (stateIncentiveDetailSearchFilters?.data !== false) {
            if (stateIncentiveDetailSearchFilters?.data?.page_meta_description !== null) {
                return stateIncentiveDetailSearchFilters?.data?.page_meta_description;
            }
        }
        return null;
    }, [stateIncentiveDetailSearchFilters]);



    // useEffect(() => {
    //     if (!isEmpty(page_title)) {
    //         document.title = page_title;
    //     }
    //     if (!isEmpty(page_meta_description)) {
    //         document.querySelector('meta[name="description"]').setAttribute("content", page_meta_description);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [page_title, page_meta_description]);

    useEffect(() => {
        if (filter?.zip_code.length === 5) {
            handleZipBlur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.zip_code]);

    useEffect(() => {
        if (isLogin) {
            setFilterState(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData,getLocalStorageData('user_typed_zip_code')));
            setFilter(defaultValueIncentiveDetailSearchFilter(userData, '', false, permData,getLocalStorageData('user_typed_zip_code')));
            handleZipBlur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    useEffect(() => {
        if (!isEmpty(make) && firstSearch === true && !isEmpty(String(filterState?.model_year))) {
            setTimeout(() => {
                onSearchClick();
            }, 100);
            setFirstSearch(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState?.model_year]);

    const filters = useMemo(() => {
        if (stateIncentiveDetailSearchFilters.data !== false) {
            return stateIncentiveDetailSearchFilters.data;
        }
        return null;
    }, [stateIncentiveDetailSearchFilters]);

    const incentiveData = useMemo(() => {
        if (stateIncentiveDetailSearch.data !== false) {
            return stateIncentiveDetailSearch.data;
        }
        return null;
    }, [stateIncentiveDetailSearch]);

    const loading = useMemo(() => {
        return stateIncentiveDetailSearch?.loading;
    }, [stateIncentiveDetailSearch]);

    useEffect(() => {

        if (
            stateIncentiveDetailSearch.loading === false &&
            stateIncentiveDetailSearch.error === ""
        ) {
            if (!isMobile) {
                onSearch(filterState);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState, filters]);

    useEffect(() => {

        if (
            stateIncentiveDetailSearchFilters.loading === false &&
            stateIncentiveDetailSearchFilters.error === ""
        ) {

            if (currentFilterChangeName !== "zip_code" && currentFilterChangeName !== "purchase_type" && currentFilterChangeName !== "model_year") {
                getIncentiveFinderFilters();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState?.make, filterState?.model, filterState?.trim, onReste]);

    useEffect(() => {

        if (
            stateIncentiveDetailSearchFilters.loading === false &&
            stateIncentiveDetailSearchFilters.error === ""
        ) {

            if (filters?.model?.length === 1) {
                if (filters?.model?.[0] !== filterState?.model && !isEmpty(filters?.model?.[0]) && firstFilter === false) {
                    onChangeFilter("model", filters?.model?.[0], true);
                }
            }
            if (filters?.trim?.length === 1) {
                if (filters?.trim?.[0] !== filterState?.trim && !isEmpty(filters?.trim?.[0]) && firstFilter === false) {
                    onChangeFilter("trim", filters?.trim?.[0], true);
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);



    const getIncentiveFinderFilters = async () => {
        dispatch(setIncentiveDetailSearchFiltersLoading(true));
        try {
            const response = await apiHelper.postIncentiveDetailSearchFilter(filterState);
            var filter_data = {};
            if (response.status === 200) {
                if (response?.data?.status === 1) {
                    filter_data = response?.data?.body;
                    dispatch(
                        updateIncentiveDetailSearchFiltersData({
                            loading: false,
                            status: true,
                            data: filter_data,
                        })
                    );

                    if (filterState?.make !== filter_data?.selected_make && !isEmpty(filterState?.model) && !isEmpty(filter_data?.selected_make)) {
                        onChangeFilter("make", filter_data?.selected_make, true);
                    }

                    setTimeout(() => {

                        if (filter_data?.model_year?.length === 1) {
                            if (String(filter_data?.model_year?.[0]) !== String(filterState?.model_year) && !isEmpty(String(filter_data?.model_year?.[0])) && firstFilter === false) {
                                onChangeFilter("model_year", String(filter_data?.model_year?.[0]), true);
                            }
                        }
                    }, 100);
                    setTimeout(() => {
                        if (String(filterState?.model_year) !== String(filter_data?.model_year?.[0])) {
                            if (firstFilter === true && !isEmpty(make)) {
                                setFirstFilter(false);
                                onChangeFilter("model_year", String(permData?.model_year));
                            }
                        }
                    }, 1);
                } else if (response.data.status === 2) {
                    dispatch(setIncentiveDetailSearchFiltersLoading(false));
                    askLogin();
                } else if (response.data.status === 0) {
                    dispatch(
                        updateIncentiveDetailSearchFiltersData({
                            loading: false,
                            status: false,
                            error: response.data,
                        })
                    );
                }
            }
        } catch (error) {
            console.error(error);
            dispatch(
                updateIncentiveDetailSearchFiltersData({
                    loading: false,
                    status: false,
                    error: error,
                })
            );
        }
    };

    const handleHideShowClick = (rowId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    };

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statePopUpData]);

    const onLogin = (x) => {

    }

    const askLogin = () => {
        clearLoginUserData();
        dispatch(updateUserDetails(null));
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
            onLogin(cc);
        }));
    }

    const onChangeFilter = async (name, value1, setModel = false) => {

        let value = { ...filterState }
        let value_ = { ...value };
        value_[name] = value1;


        if (name === 'zip_code') {
            if (value1?.length < 5) {
                setZipCodeError("Enter a valid Zip code.");
            } else {
                setZipCodeError(false);
            }
        }
        if (name === "make" && setModel === false) {
            value_.model = "";
            value_.trim = "";
            value_.model_year = "";
        }
        //  else if (name === "make" && setModel === true) {
        //     value_.trim = "";
        // }
        if (name === "model" && setModel === false) {
            value_.trim = "";
            value_.model_year = "";
        }
        if (name === "trim" && setModel === false) {
            value_.model_year = "";
        }
        setCurrentFilterChangeName(name)
        setFilterState(value_);

        if (incentiveData !== false && firstFind === false) {
            dispatch(
                updateIncentiveDetailSearchData({
                    loading: false,
                    status: true,
                    data: false
                })
            );
        }

        if (name === 'zip_code') {
            if (value1?.length === 5) {
                dispatch(setIncentiveDetailSearchFiltersLoading(true));
                const validZipCode = await isValidateZipcode(value1);
                dispatch(setIncentiveDetailSearchFiltersLoading(false));
                if (validZipCode === false) {
                    setZipCodeError("Enter a valid Zip code.");
                    setTimeout(() => {

                        const input = document.querySelector('.zip-focus');

                        // Check if the element exists and has the class
                        if (input && input.classList.contains('zip-focus')) {
                            input.focus(); // Give focus if the class is present
                        }
                    }, 100);
                } else {
                    setZipCodeError(false);
                }
            }
        }

    };

    const onSearchClick = () => {

        let tempErrorState = { ...defaultBlankFilterError() };
        if (filterState?.make?.trim() === "") {
            tempErrorState = { ...tempErrorState, make: true }
        }
        if (filterState?.model?.trim() === "") {
            tempErrorState = { ...tempErrorState, model: true }
        }
        if (filterState?.model_year?.trim() === "") {
            tempErrorState = { ...tempErrorState, model_year: true }
        }
        if (filterState?.zip_code?.trim() === "") {
            setZipCodeError(true);
        }

        if (filters?.trim?.length > 0) {

            if (filterState?.trim?.trim() === "") {
                tempErrorState = { ...tempErrorState, trim: true }
            }
        }
        // console.log("ffff",filters);
        

        setBlankFilterError(tempErrorState);
        const hasTrueValue = Object.values(tempErrorState).some(value => value === true);

        if (!hasTrueValue && !zipCodeError) {
            setFilter(filterState);
        }
    };
    const onSearch = (setFilterData = false) => {
        if (!setFilterData) {
            setFilterData = JSON.parse(JSON.stringify(filterState))
        }
        let tempErrorState = { ...defaultBlankFilterError() };
        if (setFilterData?.make?.trim() === "") {
            tempErrorState = { ...tempErrorState, make: true }
        }
        if (setFilterData?.model?.trim() === "") {
            tempErrorState = { ...tempErrorState, model: true }
        }
        if (String(setFilterData?.model_year)?.trim() === "") {
            tempErrorState = { ...tempErrorState, model_year: true }
        }
        if (setFilterData?.zip_code?.trim() === "") {
            setZipCodeError(true);
        }
        if (filters?.trim?.length > 0) {

            if (setFilterData?.trim?.trim() === "") {

                if (filters?.is_blank_trim_available === true && filters?.available_blank_trim_model_years?.length > 0 && !isEmpty(setFilterData?.model_year) && filters?.available_blank_trim_model_years?.includes(setFilterData?.model_year) !== true) {
                    tempErrorState = { ...tempErrorState, trim: true }
                } else if (filters?.is_blank_trim_available === false) {
                    tempErrorState = { ...tempErrorState, trim: true }
                }
            }
        }

        setBlankFilterError(tempErrorState);
        const hasTrueValue = Object.values(tempErrorState).some(value => value === true);

        if (!hasTrueValue && !zipCodeError) {
            setFilter(setFilterData);
        }
    };

    const onResetClick = () => {
        // getIncentiveFinderFilters();
        dispatch(
            updateIncentiveDetailSearchData({
                loading: false,
                status: true,
                data: false,
                filter: false
            })
        );
        // setTimeout(() => {
        setFilter(defaultValueIncentiveDetailSearchFilter(userData, "", true));
        setFilterState(defaultValueIncentiveDetailSearchFilter(userData, "", true));
        setOnReset(!onReste);
        setCurrentFilterChangeName("reset");
        setZipCodeError("Enter a valid Zip code.");
        setTimeout(() => {
            window.scrollTo(0, 0); // Add a small delay
        }, 100);
        history.push('/incentivefinder/', null, { shallow: true });
        // onChangeFilter("model_year", String(stateIncentiveDetailSearchFilters?.data?.model_year?.[0]));
        // }, 100);
    }

    const handleZipBlur = async (zipCode) => {

        if (
            filterState?.zip_code.toString().length > 5 ||
            filterState?.zip_code.toString().length < 5
        ) {
            setZipCodeError(true);
            return false;
        }
        dispatch(setIncentiveDetailSearchFiltersLoading(true));

        if (filterState.zip_code !== lastValidatedZipcode) {
            try {
                const zipcodeResponse = await apiHelper.postValidateZipcode(filterState);
                if (zipcodeResponse.data.statusCode === 404) {
                    setZipCodeError(true);
                } else {
                    setZipCodeError(false);
                }
                setlastValidatedZipcode(filterState.zip_code);
                dispatch(setIncentiveDetailSearchFiltersLoading(false));
            } catch (error) {
                console.error(error);
                dispatch(setIncentiveDetailSearchFiltersLoading(false));
            }
        } else {
            dispatch(setIncentiveDetailSearchFiltersLoading(false));
        }
    };

    //search section

    async function getIncentiveDetailSearchData(params = {}) {

        // if (
        //     stateIncentiveDetailSearch.filter !== undefined &&
        //     stateIncentiveDetailSearch.filter !== false
        // ) {
        //     const lastFilter = ArrayAsc(stateIncentiveDetailSearch.filter);
        //     const currentFilter = ArrayAsc(filterState);
        //     if (JSON.stringify(lastFilter) === JSON.stringify(currentFilter)) {
        //         return false;
        //     }
        // }

        dispatch(setIncentiveDetailSearchLoading(true));
        setExpandedRows({});
        try {
            const response = await apiHelper.postIncentiveDetailSearch(params);
            var res_data = null;

            if (response.data.status === 1) {
                res_data = response.data.body;
                handleClose();
                dispatch(
                    updateIncentiveDetailSearchData({
                        loading: false,
                        status: true,
                        data: res_data,
                        filter: filter,
                    })
                );
                if (firstFind === true) {
                    setFirstFind(false);
                }
                if (res_data?.user_attributes !== undefined && res_data?.user_attributes !== null && res_data?.user_attributes !== undefined) {
                    let tempUserData = { ...userData };
                    tempUserData.user_attributes = res_data?.user_attributes;
                    helper.setLoginUserData(tempUserData)
                }
                setLocalStorageData('user_typed_zip_code', filter?.zip_code);
                history.push('/incentivefinder/' + res_data?.vehicle_data?.purchase_type + "/" + CryptoJS.MD5(res_data?.vehicle_data?.vehicle_id?.toString()).toString(CryptoJS.enc.Hex) + "/" + createSeoFriendlyUrl(res_data?.vehicle_data?.make) + "/" + createSeoFriendlyUrl(res_data?.vehicle_data?.model) + "/" + filter?.model_year + "/" + createSeoFriendlyUrl(res_data?.vehicle_data?.trim), null, { shallow: true });

            } else if (response?.data?.status === 2) {
                askLogin();
            } else {
                dispatch(
                    updateIncentiveDetailSearchData({
                        loading: false,
                        status: true,
                        data: false,
                        filter: false
                    })
                );
            }
        } catch (error) {
            console.error(error);
            dispatch(
                updateIncentiveDetailSearchData({
                    loading: false,
                    status: false,
                    error: error,
                })
            );
        }
    }

    useEffect(() => {
        if (
            stateIncentiveDetailSearch.loading === false &&
            stateIncentiveDetailSearch.error === ""
        ) {
            getIncentiveDetailSearchData(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    // if (first === true) {
    //     if (userData === false || userData === undefined) {
    //         askLogin();
    //     } else if (isEmpty(make)) {
    //         dispatch(
    //             updateIncentiveDetailSearchData({
    //                 loading: false,
    //                 status: true,
    //                 data: false,
    //                 filter: false,
    //             })
    //         );
    //     }
    //     setFirst(false);
    // }

    return (
        <Fragment>
             <Helmet>
                <title>{page_title}</title>
                <meta name="description" content={page_meta_description} />
            </Helmet>
            {isEmpty(stateIncentiveDetailSearch?.error?.msg) && (
                <Fragment>
                    <IncentiveDetailSearchDesktop
                        expandedRows={expandedRows}
                        handleHideShowClick={handleHideShowClick}
                        filterList={stateIncentiveDetailSearchFilters?.data}
                        filterloading={stateIncentiveDetailSearchFilters?.loading}
                        onChangeFilter={onChangeFilter}
                        value={filterState}
                        onSearchClick={onSearchClick}
                        onResetClick={onResetClick}
                        handleZipBlur={handleZipBlur}
                        zipCodeError={zipCodeError}
                        blankFilterError={blankFilterError}
                        incentiveData={incentiveData}
                        tooltipText={stateIncentiveDetailSearchFilters?.data?.tooltips}
                        loading={loading}
                    />
                    <IncentiveDetailSearchMobile
                        loading={stateIncentiveDetailSearchFilters?.loading}
                        expandedRows={expandedRows}
                        handleHideShowClick={handleHideShowClick}
                        filterList={stateIncentiveDetailSearchFilters?.data}
                        filterloading={stateIncentiveDetailSearchFilters?.loading}
                        onChangeFilter={onChangeFilter}
                        value={filterState}
                        onSearchClick={onSearchClick}
                        onResetClick={onResetClick}
                        handleZipBlur={handleZipBlur}
                        zipCodeError={zipCodeError}
                        blankFilterError={blankFilterError}
                        incentiveData={incentiveData}
                        tooltipText={stateIncentiveDetailSearchFilters?.data?.tooltips}
                        show={show}
                        handleShow={handleShow}
                        handleClose={handleClose}
                    />
                </Fragment>
            )}
        </Fragment>
    )
}

export default withRouter(IncentiveDetailSearch);