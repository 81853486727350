import React from 'react';
import ReactDOM from 'react-dom/client';


import { BrowserRouter } from "react-router-dom";


//css
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';


import App from "./route/index";
import { Provider } from 'react-redux'
import { configureStore } from "./store";
import { HelmetProvider } from 'react-helmet-async';

const store = configureStore({});

//basename="incentives/" 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter >
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
);

