import React, { useMemo, useEffect, Fragment, useState } from "react";
import * as apiHelper from "../helper/api_helper";
import { arraysAreEqual, isEmpty } from "../helper/project_helper";
import { useSelector, useDispatch } from "react-redux";
import {
  setEVIncentiveFinderFiltersLoading,
  updateEVIncentiveFinderFiltersData,
} from "../store/action";
import "@fontsource/dm-sans";
import { Row, Col, Form, Button } from 'react-bootstrap';

const defaultValueIncentiveFinderFilters = (default_model_year = "") => {
  return {
    make: "",
    model: "",
    model_year: default_model_year,
    trim: "",
    base_msrp: '',
    range: '',
    eligible: false,
    potential_savings: false,
    segments: ''
  };
};

const EVIncentiveFinderFilters = ({
  value = defaultValueIncentiveFinderFilters(),
  onChange,
  onSearch,
  reset,
  onClickCompareVehicle,
  compareVehicles,
  compareVehiclesError,
  loading
}) => {
  const dispatch = useDispatch();
  const [selectedSegments, setSelectedSegments] = useState(value?.segments === '' ? [] : value?.segments?.split(","));
  const [selectedModelYear, setSelectedModelYear] = useState(value?.model_year === '' ? [] : value?.model_year?.split(","));
  //const [value, setValue ]= useState( defaultValueIncentiveFinderFilters() );
  
  const { stateEVIncentiveFinderFilters } = useSelector((state) => ({
    stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
  }));

  useEffect(() => {
    if (
      stateEVIncentiveFinderFilters.status === false &&
      stateEVIncentiveFinderFilters.error === ""
    ) {
      if (stateEVIncentiveFinderFilters.loading === false) {
        getIncentiveFinderFilters();
      }
    }
  });

  const filters = useMemo(() => {
    if (stateEVIncentiveFinderFilters.data !== false) {
      return stateEVIncentiveFinderFilters.data;
    }
    return null;
  }, [stateEVIncentiveFinderFilters]);

  var isLoading = useMemo(() => {
    return stateEVIncentiveFinderFilters.loading || false;
  }, [stateEVIncentiveFinderFilters]);

  const findKeyByValue = (obj, value, excludeKeys = []) => {
    return Object.keys(obj)
      .filter(key => !excludeKeys.includes(key))  // Exclude specified keys
      .find(key => obj[key].includes(value));  // Find the key that contains the value
  };

  const onUpdate = (name, value1) => {
    let value_ = { ...value };
    value_[name] = value1;

    if (name?.trim() === "make") {
      if (value1 !== '') {
        if (filters.model[value1].indexOf(value?.model) === -1) {
          value_.model = "";
        }
      }

      if(!isEmpty(value_.make))
      {
        if(filters?.model[value_?.make] && filters?.model[value_?.make].length === 1)
        {
          value_.model = filters?.model[value_?.make][0];
        }
      }

    }
    if (name?.trim() === "model") {
      if (value1 !== '') {
        const key = findKeyByValue(filters?.model, value_?.model, ['all']);
        if (!isEmpty(key)) {
          if (key !== value?.make) {
            value_.make = key;
          }
        }
      }
    }
    onChange(value_);
    onSearch(value_);
  };

  useEffect(() => {
    if (!arraysAreEqual(selectedSegments, value.segments)) {
      onUpdate('segments', selectedSegments.join(","))
    }
  }, [selectedSegments]);

  const handleSegmentChange = async (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the value to the array if checked
      setSelectedSegments((prev) => [...prev, value]);
    } else {
      // Remove the value from the array if unchecked
      setSelectedSegments((prev) =>
        prev.filter((option) => option !== value)
      );
    }
  };

  useEffect(() => {
    if (!arraysAreEqual(selectedModelYear, value.model_year)) {

      onUpdate('model_year', selectedModelYear.join(","))
    }
  }, [selectedModelYear]);

  const handleModelYearChange = async (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the value to the array if checked
      setSelectedModelYear((prev) => [...prev, value]);
    } else {
      // Remove the value from the array if unchecked
      setSelectedModelYear((prev) =>
        prev.filter((option) => option !== value)
      );
    }
  };


  const getIncentiveFinderFilters = async () => {
    dispatch(setEVIncentiveFinderFiltersLoading(true));
    try {
      const response = await apiHelper.postGetEVIncentiveFinderFilters();
      var filter_data = {};
      if (response.status === 200) {
        filter_data = response.data.body.data;
      }
      dispatch(
        updateEVIncentiveFinderFiltersData({
          loading: false,
          status: true,
          data: filter_data,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        updateEVIncentiveFinderFiltersData({
          loading: false,
          status: false,
          error: error,
        })
      );
    }
  };

  const onResetClick = () => {
    reset(defaultValueIncentiveFinderFilters());
  };

  return (
    <Fragment>
      <Col className="" lg={12} style={{ background: "#fff" }}>
        <div>
          <Col>
            <div className="d-flex flex-column mb-2">
              <button
                disabled={compareVehicles.length > 0 ? false : true}
                className={compareVehicles.length > 0 ? "compare-active" : "btn-Compare"} onClick={onClickCompareVehicle}>Compare {compareVehicles.length > 0 && "(" + compareVehicles.length + ")"}</button>
              {compareVehiclesError && (<span className="warning-msg">{compareVehiclesError}</span>)}
            </div>
          </Col>
          {/* {basic view filter} */}
          <Row className="flex-column">
            <Col className="col pb-3 pb-lg-3">
              <SelectInput
                label={"Make"}
                value={value?.make}
                options={filters?.make}
                onChange={(x) => onUpdate("make", x)}
                disabled={isLoading || loading}
                bold_class={
                  value.make === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
              />
            </Col>
            <Col className="col pb-3 pb-lg-3">
              <SelectInput
                label={"Model"}
                value={value?.model}
                options={
                  value?.make ? filters?.model[value?.make] : filters?.model['all']
                }
                onChange={(x) => onUpdate("model", x)}
                disabled={isLoading || loading}
                bold_class={
                  value.model === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
              />
            </Col>
            <Col className=" pb-3 pb-lg-3">
              <SelectInput
                label={"Base Price"}
                value={value?.base_msrp}
                options={filters?.base_msrp}
                onChange={(x) => onUpdate("base_msrp", x)}
                disabled={isLoading || loading}
                isTextValueFilter={true}
                bold_class={
                  value.base_msrp === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
              />
            </Col>
            <Col className="col pb-3 pb-lg-3">
              <SelectInput
                label={"Range (mi)"}
                value={value?.range}
                options={filters?.range}
                onChange={(x) => onUpdate("range", x)}
                disabled={isLoading || loading}
                isTextValueFilter={true}
                bold_class={
                  value.range === ""
                    ? "blank-select-color"
                    : "filter_select_bold"
                }
              />
            </Col>
            <Col>
              <span className="filter_label">Model Year</span>
              <Form>
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="my-2 d-flex flex-wrap">
                    {filters?.model_year &&
                      filters.model_year?.map((model_year, keyIndex) => {
                        return (
                          <Form.Check
                            key={keyIndex}
                            type={type}
                            id={`default-${type}`}
                            label={model_year}
                            className="me-4 form-checkbox"
                            style={{ fontWeight: "600" }}
                            value={String(model_year)}
                            onChange={handleModelYearChange}
                            disabled={isLoading || loading}
                            checked={value?.model_year?.split(",").includes(String(model_year))}
                          />
                        )
                      })}
                  </div>
                ))}
              </Form>
            </Col>
            <Col>
              <span className="filter_label">Segment</span>
              <Form >
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="mt-2 d-flex flex-wrap" style={{ marginBottom: "16px" }}>
                    {filters?.segments &&
                      filters.segments?.map((segment, keyIndex) => {
                        return (
                          <Form.Check
                            key={"s_chk" + keyIndex}
                            type={type}
                            id={`default-${type}`}
                            value={segment?.value}
                            label={segment?.text}
                            className=" form-checkbox me-3"
                            style={{ fontWeight: "600" }}
                            onChange={handleSegmentChange}
                            disabled={isLoading || loading}
                            checked={value?.segments?.split(",").includes(String(segment?.value))}
                          />
                        )
                      })
                    }
                  </div>
                ))}
              </Form>
            </Col>
            <Col>
              <Form className="mb-3">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Only show vehicles eligible for Federal Tax Credit"
                  className="form-check w-75  d-flex align-items-center toggle-switch"
                  onChange={(x) => onUpdate("eligible", x.target.checked)}
                  checked={value?.eligible}
                  disabled={isLoading || loading}
                />
              </Form>
            </Col>
            <Col>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Only show vehicles with Potential Savings"
                  className="form-check w-75 d-flex align-items-center toggle-switch"
                  onChange={(x) => onUpdate("potential_savings", x.target.checked)}
                  checked={value?.potential_savings}
                  disabled={isLoading || loading}
                />
              </Form>
            </Col>
            <Col lg={2} className="col pb-3 pb-lg-3">
              <Row className="search_row">
                <Col className="pb-3 pb-lg-3">
                  <Button
                    type="button"
                    className="reset-link button-set ps-0 text-decoration-underline font-black green-hover-text"
                    onClick={onResetClick}
                    disabled={isLoading || loading}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Fragment>
  );
};

const SelectInput = ({
  label,
  value = "",
  onChange,
  options = [],
  placeHolder = "Any",
  disabled = false,
  bold_class = "",
  customClassName = "",
  isTextValueFilter = false
}) => {
  const onUpdate = (e) => {
    onChange(e.target.value);
  };

  return (
    <Fragment>
      <label htmlFor={label.toLowerCase()} className="filter_label">
        {" "}
        {label}{" "}
      </label>
      <select
        className={
          bold_class +
          " form-select filter_select overflow-hidden border-w" +
          customClassName
        }
        aria-label="Default select example"
        id={label.toLowerCase()}
        value={value}
        onChange={onUpdate}
        disabled={disabled}
      >
        <option value=""> {placeHolder} </option>
        {options?.map((item_val, item_key) => {
          return isTextValueFilter ? (<option value={item_val?.value} key={item_key}>
            {item_val?.text}
          </option>) : (
            <option value={item_val} key={item_key}>
              {item_val}
            </option>
          );
        })}
      </select>
    </Fragment>
  );
};

const SelectWihoutAnyInput = ({
  label,
  value = "",
  onChange,
  options = [],
  disabled = false,
  bold_class = "",
  isTaxFillingStatus = false,
  customClassName = "",
  isHouseholdIncom = false
}) => {
  const onUpdate = (e) => {
    onChange(e.target.value);
  };

  return (
    <Fragment>
      <label htmlFor={label.toLowerCase()} className="filter_label">
        {" "}
        {label}{" "}
      </label>
      <select
        className={
          bold_class +
          " form-select filter_select overflow-hidden " +
          customClassName
        }
        aria-label="Default select example"
        id={label.toLowerCase()}
        value={value}
        onChange={onUpdate}
        disabled={disabled}
      >
        {options?.map((item_val, item_key) => {
          return (
            <option value={item_val} key={item_key}>
              {item_val}
            </option>
          );
        })}
      </select>
    </Fragment>
  );
};

export { EVIncentiveFinderFilters, defaultValueIncentiveFinderFilters };
