import { Fragment, useRef, useEffect } from "react";
import { Form } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { defaultPopUpState, ArrayAsc, isEmpty, DateFormatterLastUpdated, createSeoFriendlyUrl } from '../helper/project_helper';

const ChangePopup = (
    {
        handleChangeClick,
        popupLoading,
        filter,
        onChangePopUpField,
        filterList,
        onChangeClickVehicle,
        filterState,
        trimError
    }
) => {  
    
    const divchangeVehicleRef = useRef(null);

    const handleClickOutside = (event) => {

        if (divchangeVehicleRef.current && !divchangeVehicleRef.current.contains(event.target) && !event.target.closest(`.model-detail-pro-detailpage`) && !event.target.closest(`.compare-change-button`)) {
            handleChangeClick();
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <div className="border border-dark-subtle p-4 rounded model-detail-pro-detailpage" ref={divchangeVehicleRef}>
                <div className="mb-2 text-start">
                    <div className='popup-close pt-1 cursor-pointer'>
                        <svg onClick={() => handleChangeClick()} width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <label className="font-w font-midium font-color">Make</label>
                    <Form.Select
                        className={"font-small font-wxl font-color"}
                        aria-label="Default select example"
                        disabled={popupLoading}
                        value={filter?.make}
                        onChange={(x) => onChangePopUpField("make", x.target.value)}
                    >
                        <option value=''>Select</option>
                        {filterList?.make?.map((element, key) => {
                            return (
                                <option key={key} value={element}>{element}</option>
                            );
                        })}
                    </Form.Select>
                </div>
                <div className="mb-2 text-start">
                    <label className="font-w font-midium font-color">Model</label>
                    <Form.Select
                        className={"font-small font-wxl font-color"}
                        aria-label="Default select example"
                        disabled={popupLoading}
                        value={filter?.model}
                        onChange={(x) => onChangePopUpField("model", x.target.value)}
                    >
                        <option value=''>Select</option>
                        {filterList?.model?.map((element, key) => {
                            return (
                                <option key={key} value={element}>{element}</option>
                            );
                        })}
                    </Form.Select>
                </div>
                <div className="mb-2 text-start">
                    <label className="font-w font-midium font-color">Trim</label>
                    <Form.Select
                        className={"font-small font-wxl font-color"}
                        aria-label="Default select example"
                        value={filter?.trim}
                        onChange={(x) => onChangePopUpField("trim", x.target.value)}
                        disabled={filterList?.trim?.length === 0 || popupLoading}
                    >
                        {filterList?.trim?.length > 0 &&
                        <option value=''>Select</option>} 
                        {filterList?.trim?.map((element, key) => {
                            return (
                                <option key={key} value={element}>{element}</option>
                            );
                        })}
                    </Form.Select>
                </div>
                <div className="text-start">
                    <label className="font-w font-midium font-color">Model Year</label>
                    <div>
                        {filterList?.model_year?.map((element, key) => {
                            return (
                                <Form.Check
                                    key={key}
                                    inline
                                    label={element}
                                    onChange={(x) => onChangePopUpField("model_year", x.target.value)}
                                    name="model_year"
                                    checked={Number(element) === Number(filter?.model_year)}
                                    type="checkbox"
                                    value={element}
                                    className="radio-check"
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button"
                        disabled={(isEmpty(filter?.make) || isEmpty(filter?.model) || isEmpty(filter?.model_year) || (filterList?.trim?.length > 0 && isEmpty(filter?.trim) && filterList?.is_blank_trim_available === false) || (filterList?.is_blank_trim_available === true && filterList?.available_blank_trim_model_years?.length > 0 && !isEmpty(filter?.model_year) && filterList?.available_blank_trim_model_years?.includes(filter?.model_year) !== true))
                            ||
                            (filter?.make === filterState?.make && filter?.model === filterState?.model && filter?.model_year === filterState?.model_year && filter?.trim === filterState?.trim)
                        }
                        className="btn-learn-more text-decoration-none"
                        onClick={() => onChangeClickVehicle()}> Update </button>
                </div>
            </div>
        </Fragment>
    );
}

export default withRouter(ChangePopup);